<template>
  <div class="rates">
    <page-data-section :title="sectionTitle"
                       class="rates__data-section">
      <p v-for="(message, index) in messages"
         :key="index"
         class="rates__message"
         v-html="message"></p>
      <sk-note :text="importantNoteText" />
    </page-data-section>
    <!-- <page-data-table :data="rates"
                     :cells="tableCells"
                     class="rates__data-table"
                     @download="catchDownload" /> -->
    <article class="supplier-rates">
      <h3 class="rates__message">{{ $gettext('An overview of the new rates, applicable from September 1st 2023, can be found here:') }}</h3>
      <div class="rates-documents__btn-cont">
        <a class="supplier-rates--downloading"
           href="/static/docs/honorarsatser_selvstendig_naeringsdrivende.pdf"
           download> {{ $gettext('Rates for self-employed interpreters') }}</a>
        <a class="supplier-rates--downloading"
           href="/static/docs/honorarsatser_for_honorarmottakere.pdf"
           download> {{ $gettext('Rates for interpreters who are honorar recipients') }}</a>
      </div>
    </article>
  </div>
</template>

<script>
  import {mapState, mapActions, mapGetters} from 'vuex';
  import PageDataSection from '@/components/shared_components/page_data/PageDataSection';
  // import PageDataTable from '@/components/shared_components/page_data/PageDataTable';

  export default {
    asyncData({store}) {
      let promisesArr = [];

      if (!store.state.RatesStore.rates) {
        promisesArr = [...promisesArr, store.dispatch('RatesStore/getRates')];
      }

      return promisesArr ? Promise.all(promisesArr) : promisesArr;
    },
    components: {
      'page-data-section': PageDataSection,
      // 'page-data-table': PageDataTable
    },
    data() {
      return {
        domain: this.$getString('domainData', 'platformName')
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userIsInterpreter']),
      ...mapState('RatesStore', {
        rates: ({rates}) => rates || []
      }),
      sectionTitle() { return this.$gettext('Rates'); },
      messages() {
        return this.userIsInterpreter
          ? [
            this.$gettextInterpolate(
              this.$gettext('%{domain} is the official provider of interpretation services for a large number of government offices, and private companies.'), {domain: this.domain}
            ),
            this.$gettext('This page provides a selection of agreements with our customers, along with the rates set for their interpretation sessions.')
          ]
          : [
            this.$gettextInterpolate(
              this.$gettext('%{domain} is the official provider of interpretation services and translation services for a large number of government offices, and private companies.'), {domain: this.domain}
            ),
            this.$gettext('This page provides a collection of our standard rates, and additional related documentation.'),
            this.$gettextInterpolate(
              this.$gettext('These files present our standard rates for common assignment types. For conference interpretation, audio/video transcription, and other larger projects please contact us at <a class="sk-link sk-link--gray-bg" href="mailto:%{email}" target="_blank">%{email}</a> for a non-binding offer.'), {
                email: this.$getString('domainData', 'supportEmail')
              }
            )
          ];
      },
      importantNoteText() {
        const interpreterTemplate = this.$gettext('These files provide a simplification of the tender document published on mercell. We recommended that you refer to the actual tender document as well.');
        const enterpriseTemplate = this.$gettextInterpolate(this.$gettext('Please contact us if you wish to enter into a 1-year agreement with %{domain}. We look forward to adjusting our rates & services to your needs.'), {domain: this.domain});

        return this.userIsInterpreter ? interpreterTemplate : enterpriseTemplate;
      },
      tableCells() {
        return [
          {
            name: this.$gettext('Nr'),
            dataField: 'id',
            type: 'itemNumber',
            fieldTemplate: this.$gettext('#%{index}')
          },
          {
            name: this.$gettext('File name'),
            dataField: 'title',
            type: 'text'
          },
          {
            name: this.$gettext('Download'),
            type: 'download'
          }
        ];
      }
    },
    methods: {
      ...mapActions('RatesStore', ['getRatesDoc']),
      catchDownload(id) {
        this.getRatesDoc(id).then((link) => {
          window.open(link, '_blank');
        });
      }
    },
    beforeDestroy() {
      this.$store.commit('RatesStore/clearStore');
    }
  };
</script>

<style scoped>
  .rates {
    width: 100%;
    max-width: 800px;
  }

  .rates__message {
    margin-bottom: 20px;
  }

  .rates__data-table {
    margin-top: 20px;
  }

  .rates-documents__btn-cont {
    margin-top: 10px;
    margin-bottom: 30px;
    text-align: center;
  }

  .supplier-rates {
    display: block;
    width: 100%;
    padding: 30px;
    background-color: #fff;
  }

  .supplier-rates--downloading {
    display: inline-block;
    width: 80%;
    height: 36px;
    margin-top: 15px;
    padding: 7px 10px 5px 30px;
    border: 1px solid var(--color-primary);
    border-radius: 3px;
    background-image: url(~@assets/imgs/salita/download_orange_icon.svg);
    background-position: 5px;
    background-repeat: no-repeat;
  }

</style>

<style>
  .rates__data-table .page-data-table__row--header .page-data-table__cell:first-child,
  .rates__data-table .page-data-table__cell-wrapper--itemNumber {
    max-width: 20%;
  }

  .rates__data-table .page-data-table__row--header .page-data-table__cell:last-child,
  .rates__data-table .page-data-table__cell-wrapper:last-child .page-data-table__cell {
    justify-content: flex-end;
  }

  .rates__data-table .sk-btn--downloading:hover {
    background-position-y: 60%;
    transition: 0.2s;
  }
</style>
