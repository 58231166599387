// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/salita/download_orange_icon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.rates[data-v-23c3dd4f] {\n  width: 100%;\n  max-width: 800px;\n}\n.rates__message[data-v-23c3dd4f] {\n  margin-bottom: 20px;\n}\n.rates__data-table[data-v-23c3dd4f] {\n  margin-top: 20px;\n}\n.rates-documents__btn-cont[data-v-23c3dd4f] {\n  margin-top: 10px;\n  margin-bottom: 30px;\n  text-align: center;\n}\n.supplier-rates[data-v-23c3dd4f] {\n  display: block;\n  width: 100%;\n  padding: 30px;\n  background-color: #fff;\n}\n.supplier-rates--downloading[data-v-23c3dd4f] {\n  display: inline-block;\n  width: 80%;\n  height: 36px;\n  margin-top: 15px;\n  padding: 7px 10px 5px 30px;\n  border: 1px solid var(--color-primary);\n  border-radius: 3px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: 5px;\n  background-repeat: no-repeat;\n}\n\n", ""]);
// Exports
module.exports = exports;
